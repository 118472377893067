/* Parent container styles */
.app-container {
    display: flex; /* Use Flexbox to center the grid */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 90vh; /* Full viewport height */
    margin: 0; /* Remove default margins */
    padding: 0; /* Remove default padding */
}

/* Grid container styles */
.app-grid {
    display: flex; /* Horizontal alignment for grid items */
    gap: 20px; /* Space between the AppHero components */
}
