.address-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px 8px;
    border: 1px solid #ccc;
    /*border-radius: 8px;*/
    width: 100%;
    box-sizing: border-box;
}

.address {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.address-label {
    color: #666; /* Equivalent to Colors.grey20 */
    font-size: 14px;
    font-family: Arial, sans-serif;
}

.copy-button {
    background-color: #0D3354;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 16px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.copy-button:hover {
    background-color: #27537C;
}


.disclaimer-box {
    background-color: #fff5f0; /* Warm soft orange background */
    color: #b03e00;            /* Darker warm red text */
    border-left: 5px solid #ff7043; /* Strong orange/red left border */
    padding: 16px;             /* Padding inside the box */
    border-radius: 8px;        /* Rounded corners */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin: 16px 0;            /* Space above and below */
}

.disclaimer-title {
    margin: 0 0 8px 0;         /* Space below the title */
    font-size: 18px;           /* Larger font for title */
    font-weight: bold;         /* Bold title */
    color: #d84315;            /* Dark orange/red title */
}

.disclaimer-text {
    font-size: 14px;           /* Readable font size */
    line-height: 1.6;          /* Proper line spacing */
    margin: 0;                 /* Remove default paragraph margins */
}

.deposit-address-container {
    background-color: #ffffff; /* Soft light gray background */
    border: 1px solid #d1d5db; /* Light gray border */
    /*border-radius: 8px;        !* Rounded corners *!*/
    padding: 16px;             /* Space inside the box */
    text-align: center;        /* Center everything inside the box */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
    width: 100%;
    margin: 20px auto;         /* Center the box horizontally */
}

.deposit-title {
    font-size: 18px;           /* Larger text for the title */
    font-weight: bold;         /* Bold title */
    color: #374151;            /* Darker gray for a professional title */
    margin-bottom: 8px;        /* Space below the title */
}

.deposit-subtitle {
    font-size: 14px;           /* Smaller subtitle text */
    color: #6b7280;            /* Medium gray for subtitle */
    margin-bottom: 16px;       /* Space below the subtitle */
}

.qr-code-image {
    width: 240px;              /* QR code size */
    height: 240px;
    border: 1px solid #e5e7eb; /* Light border around QR code */
    /*border-radius: 8px;        !* Rounded corners for the QR code *!*/
    padding: 0px;              /* Optional inner spacing for visual balance */
}

