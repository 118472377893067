.balances_view_navbar {
    display: inline-flex; /* Shrink-wrap the width to fit its content */
    flex-direction: row;  /* Align items in a row */
    gap: 8px;           /* Add 12px spacing between items */
    align-items: center; /* Align items vertically */
    justify-content: flex-start; /* Align items to the left */
    padding: 8px;       /* Add padding for spacing */
    background-color: #ffffffff; /* White background for visibility */
    border-radius: 7px;
}
