.AppHeroBox {
    background: rgba(255,255,255,.60);
    position: relative; /* Optional: Set position for more control */
    mix-blend-mode: normal;
    box-shadow: 0px 3px 7px rgba(149, 149, 149, 0.027);
    padding: 15px;
    margin-bottom: 7px;
    min-height: 100px;
    width: 500px;
    border-radius: 12px;
    border: 1px solid #CCC !important;
    overflow: hidden;
}

.AppHeroBox .text-image-row {
    display: flex; /* Use Flexbox for row alignment */
    justify-content: space-between; /* Ensure space between paragraph and image */
    align-items: center; /* Vertically align items in the row */
    width: 100%; /* Full width of the container */
    gap: 15px; /* Optional: Add space between the items */
}

.AppHeroBox p {
    flex: 1; /* Allow the paragraph to take up the remaining space */
    margin: 0; /* Remove default margins */
}

.AppHeroBox img {
    max-width: 120px; /* Fix the image size */
    height: auto; /* Maintain aspect ratio */
    margin: 0; /* Remove default margins */
}

/*.AppHeroBox img {*/
/*    float: right; !* Align the image to the right *!*/
/*    margin-left: 15px; !* Space between image and text *!*/
/*    margin-bottom: 15px; !* Space below the image *!*/
/*    max-width: 200px; !* Keep the image at a fixed size *!*/
/*    height: auto; !* Maintain aspect ratio *!*/
/*}*/

.AppHeroBox .title {
    text-align: center;
}
.AppHeroBox .btnContainer {
    text-align: center;
    clear: both;
}
.AppHeroBox .linkBTN {
        display: inline-block; /* Ensure the button behaves like a block for centering */
        /*width: 100%; !* Make the button span the full width of its container *!*/
        max-width: 200px; /* Optional: Limit the width to prevent it from being too large */
        padding: 10px 50px; /* Adjust padding for a balanced look */
        text-align: center; /* Center the text inside the button */
        margin: 0 auto !important; /* Center the button horizontally */
        font-size: 16px; /* Optional: Adjust font size */
        border-radius: 5px; /* Optional: Add rounded corners */
}
