.filterDropDown {
    font-size: 14px;
    min-width: 200px;
}

.tabBox .filter-dropdown-item {
    padding: 0.5rem 1rem; /* Matches `px-4 py-2` */
    cursor: pointer; /* Adds pointer cursor for clickability */
    transition: background-color 0.2s ease; /* Smooth hover effect */
}

.tabBox .filter-dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05); /* Bootstrap's light background color for hover */
}

.tabBox .filter-dropdown-item.active {
    background-color: #e9ecef; /* Bootstrap's gray-100 equivalent for active state */
}

.tabBox .filter-dropdown-menu {
    background-color: rgba(255, 255, 255, 0.8); /* White with high opacity */
    backdrop-filter: blur(8px); /* Blurs the content behind */
    border: 1px solid #dee2e6; /* Bootstrap's border-secondary equivalent */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
    padding: 0.5rem 0; /* Adds spacing inside the dropdown */
    position: absolute; /* Ensures it stays detached from the layout */
    z-index: 1050; /* Ensures it appears above other content */
    width: 100%; /* Ensures it matches the button width */
    transition: backdrop-filter 0.2s ease, background-color 0.2s ease; /* Smooth transition for effects */
}

.border-light-grey {
    border-color:#dee2e6 !important;
    min-width: 90px;
    width: 160px;
}

.range-picker-border {
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/*.filterDropDown .*/
