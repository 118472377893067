.tabBox {
    width: 100%;
    height: 95%;
    float: left;
    background: #ffffff8a;
    mix-blend-mode: normal;
    box-shadow: 0px 3px 7px rgba(149, 149, 149, 0.027);
    border-radius: 7px;
    padding: 15px;
    margin-bottom: 7px;
    min-height: 330px;
}

@media (max-width: 1150px) {
    .tabBox {
        margin-bottom: 5px;
    }
}

.tabBox_head {
    width: 100%;
    height: auto;
    float: left;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: -0.5px;
    position: relative;
    z-index: 5;
    font-weight: 600;
}

.tabBox_head h5 {
    font-size: 18px !important;
}

.teamperfomance .teammbrs_cnt_name span {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    color: #8f8f8f;
    font-family: sans-serif;
    letter-spacing: 0.5px;
    font-weight: 400;
}

.teamperfomance .teammbrs_cnt_name_dtl {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.teamperfoamance_amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
}

.teamperfoamance_amount strong {
    font-weight: 600;
}

.teamPerfomance_tab {
    width: 100%;
    height: auto;
    float: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.teamPerfomance_tab a {
    font-size: 14px;
    color: #a5a5a5;
    text-decoration: none;
    padding: 0 5px;
    font-family: 'nunito-regular';
    font-weight: 200 !important;
}

.teamPerfomance_tab a.active {
    color: #3c49c1;
}
